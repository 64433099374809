import React, { Component } from "react";

const defaultProps = {};
const propTypes = {};

class Footer extends Component {

	constructor(){
		super();
  }

			componentDidMount(){
				window.scrollTo(0, 0);
				var StaggerHome = $(".stagger-home");

				var tl = new TimelineLite();
				tl.staggerTo(StaggerHome, 1, {y: "0", ease: Power2.easeOut}, 0.15, "5.4");
				}

	render(){
		return <div className="bottom-info">
			<div className="bottom-left">
				<div className="overflow-hidden">
					<p className="stagger-home moved-top">
						© 2021
					</p>
				</div>
			</div>
			<div className="bottom-center">
				<div className="overflow-hidden">
					<p className="stagger-home moved-top">
						Digital & 35mm zine
					</p>
				</div>
			</div>
			<div className="bottom-right">
				<div className="overflow-hidden">
						<img src="/images/asterix.svg" className="stagger-home moved-top"/>
				</div>
			</div>
		</div>;
		}
		}

export default Footer;
