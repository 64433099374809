import React, { Component } from "react";
import { connect } from "react-redux";
import { updateNavRoute, updateProjectsData } from "../../actions/main-actions";

import projectsData from "../../scripts/projects-data.json";

const defaultProps = {};
const propTypes = {};

class Daylight extends Component {

  constructor() {
    super();
    this.linkClickHandler = this.linkClickHandler.bind(this);
  }

  componentDidMount() {
  }

  linkClickHandler(e){
    // console.log ( e.target.getAttribute("data-target") );
    console.log(e);
    console.log( e.target );
    this.props.updateNavRoute( e.target.getAttribute("data-target") );
  }

  render(){
		return <div className="master-series-container">
    <div className="master-series-bg stagger-image"></div>
    <div className="preview-image-wrapper">
      <div className="preview-mobile preview-zero">
        <div className="preview-image-mobile" onClick={this.linkClickHandler} data-target="/series/daylight"></div>
      </div>
      <div className="preview-image preview-one">
        <div className="preview-image-bg" onClick={this.linkClickHandler} data-target="/series/daylight"></div>
      </div>
      <div className="preview-image preview-two">
        <div className="preview-image-bg" onClick={this.linkClickHandler} data-target="/series/daylight"></div>
      </div>
      <div className="preview-image preview-three">
        <div className="preview-image-bg" onClick={this.linkClickHandler} data-target="/series/daylight"></div>
      </div>
      <div className="preview-image preview-four">
        <div className="preview-image-bg" onClick={this.linkClickHandler} data-target="/series/daylight"></div>
      </div>
      <div className="preview-image preview-five">
        <div className="preview-image-bg" onClick={this.linkClickHandler} data-target="/series/daylight"></div>
      </div>
    </div>
    </div>;
  	}
  }

const mapStateToProps = state => ({
	targetRoute: state.targetRoute
});

const sideMenuActions = { updateNavRoute };

export default connect( mapStateToProps, sideMenuActions )( Daylight );
