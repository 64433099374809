import React, { Component }	from "react";

const defaultProps = {};
const propTypes = {};


class Error extends Component {

	constructor(){
			super();
			this.burn = null;
			this.showBurn = null;
		}

		componentDidMount(){
			window.scrollTo(0, 0);

			var StaggerNav = $(".stagger-nav");

			var tl = new TimelineLite();
			tl.staggerTo(StaggerNav, 1, {y: "0", ease: Power2.easeOut}, 0.15, "1.5");
			}

	render(){
		return(<div className="page-wrapper hide-overflow">
		<div className="hide-overflow">
			<div className="nav-wrapper">
						<div className="top-nav">
							<div className="top-left">
								<div className="overflow-hidden">
									<div data-target="/" onClick={this.linkClickHandler} className="nav-links stagger-nav moved-top">
									<svg width="34" height="10" viewBox="0 0 34 10" fill="none">
										<path d="M3.04868 7.24462V0.215054H0V9.78495H7.02816V7.24462H3.04868Z" fill="white"/>
										<path d="M11.557 0C8.60273 0 6.70068 1.96237 6.70068 5C6.70068 8.03763 8.60273 10 11.557 10C14.5112 10 16.4133 8.03763 16.4133 5C16.4133 1.96237 14.5112 0 11.557 0ZM11.557 2.55376C12.6362 2.55376 13.1892 3.40054 13.1892 5C13.1892 6.59946 12.6362 7.44624 11.557 7.44624C10.4778 7.44624 9.92473 6.59946 9.92473 5C9.92473 3.40054 10.4778 2.55376 11.557 2.55376Z" fill="white"/>
										<path d="M20.6451 0C17.9472 0 16.4768 1.19624 16.4768 3.07796C16.4768 5.10753 18.136 5.63172 20.0246 6.14247C21.1577 6.43817 21.5624 6.55914 21.5624 7.04301C21.5624 7.40591 21.2522 7.64785 20.6586 7.64785C19.9167 7.64785 19.6064 7.33871 19.6064 6.70699H16.261C16.261 8.77688 17.8258 10 20.5372 10C23.2352 10 24.8 8.83065 24.8 6.86828C24.8 4.74462 23.0868 4.16667 21.1577 3.69624C19.9706 3.41398 19.5929 3.29301 19.5929 2.84946C19.5929 2.48656 19.8897 2.25807 20.4563 2.25807C21.0768 2.25807 21.4005 2.55376 21.4005 3.10484H24.6516C24.6516 1.04839 23.1003 0 20.6451 0Z" fill="white"/>
										<path d="M33.3121 2.75538V0.215054H24.7326V2.75538H27.498V9.78495H30.5467V2.75538H33.3121Z" fill="white"/>
										</svg>
									</div>
								</div>
							</div>
							<div className="top-right">
								<div className="overflow-hidden desktop-link">
									<a data-target="/roll" onClick={this.linkClickHandler} className="nav-links stagger-nav moved-top">
										Overview
									</a>
								</div>
								<div className="overflow-hidden mobile-link">
									<a data-target="/overview" onClick={this.linkClickHandler} className="nav-links stagger-nav moved-top">
										Overview
									</a>
								</div>
							</div>
						</div>
					</div>

		<video height="100vh" width="100%" autoPlay loop>
		<source src="../images/insomnia.mp4" type="video/mp4" />
		</video>
		<svg height="100vh" width="100%" className="error-mask">
		<mask id="maskLayer">
		<rect width="100%" height="100%" fill="#fff"></rect>
		<text fill="#000">404</text>
		</mask>

		<rect id="masked" width="100%" height="100%" fill="#000"></rect>
		</svg>
		</div>
</div>);
}
}

export default Error;
