import React, { Component } from "react";
// redux
import { connect } from "react-redux";
const menuTl = new TimelineMax({paused: true,reversed: true});


class Expand extends Component {

	constructor(props){
			super(props);
			this.state = {
				menuActive: false,
			}
			this.menuClickHandler = this.menuClickHandler.bind(this);
			this.toggleMenuActive = this.toggleMenuActive.bind(this);
			this.toggleMenu = this.toggleMenu.bind(this);
		}

		toggleMenuActive() {
			this.setState({
	      menuActive: !this.state.menuActive
	    })
		}

		menuClickHandler(e) {
			const { toggleMenuActive } = this;
			setTimeout(function(){
			menuTl.reversed() ? menuTl.play() : menuTl.reverse();
			toggleMenuActive();
		}, 100);
		}

		componentDidMount() {
			this.switch();
		}

		switch() {
			var PhotoWrapper = $(".photo-wrapper");
			var CursorImage = $(".image-expand");

	    menuTl
	      .to(PhotoWrapper, 0.35, {padding: "0"})
				.to(CursorImage, 0.35, {className:"+=reverse-max"})

			var progress = menuTl.progress(); //gets current progress
			return menuTl;
		}

		toggleMenu(e) {
			if (e) {
					e.preventDefault();
			}
			menuTl.reversed() ? menuTl.play() : menuTl.reverse();
			this.setState({
				menuActive: !this.state.menuActive
			})
		}

	render(){
		return <div className="image-expand" onClick={this.toggleMenu}>
			<div onClick={this.menuClickHandler}></div>
		</div>

		;
	}

}

export default Expand;
