import React, { Component } from "react";
import { connect } from "react-redux";
import { updateNavRoute } from "../../actions/main-actions";
import DomesticNoir from "../../components/series/domesticnoir";
import Daylight from "../../components/series/daylight";
import Moody from "../../components/series/moody";
import Dark from "../../components/series/dark";
import Gritty from "../../components/series/gritty";
import Burns from "../../components/series/burns";
import Nav from "../../components/navigation/navigation";
import Hammer from "hammerjs";

// projects data
import projectsData from "../../scripts/projects-data.json";

const defaultProps = {};
const propTypes = {};

const pageSeries = [
  {
    id: "slideOne",
    type: "Digital",
    number: 9,
    title: "Domestic Noir",
    routeUrl: "/series/domesticnoir",
    keyNumber: "1"
  },
  {
    id: "slideTwo",
    type: "35mm",
    number: 15,
    title: "Daylight",
    routeUrl: "/series/daylight",
    keyNumber: "2"
  },
  {
    id: "slideThree",
    type: "35mm",
    number: 11,
    title: "Moody",
    routeUrl: "/series/moody",
    keyNumber: "3"
  },
  {
    id: "slideFour",
    type: "Digital",
    number: 8,
    title: "Dark",
    routeUrl: "/series/dark",
    keyNumber: "4"
  },
  {
    id: "slideFive",
    type: "Digital",
    number: 10,
    title: "Gritty",
    routeUrl: "/series/gritty",
    keyNumber: "5"
  },
  {
    id: "slideSix",
    type: "35mm",
    number: 9,
    title: "Burns & Blurs",
    routeUrl: "/series/burns",
    keyNumber: "6"
  }
];

class Roll extends Component {

	constructor(){
		super();
		this.linkClickHandler = this.linkClickHandler.bind(this);

		this.state = {
			totalProjects: pageSeries.length,
			currentLabel: 0, // start at 0 for array index
			backgroundImage: "",
			eventsBlocked: false
		};
		// projects elements array - title and number
		this.homeElements = [];
		// main timeline
		this.homeTimeline = new TimelineMax({ paused: true });

		// number container
		this.numberContainer = null;
		this.keyNumberContainer = null;
		// titles container
		this.titlesContainer = null;
		// all the titles to change their rotation
		this.titleElements = [];
		// wipe element
		this.wipeElement = null;
		// main wrapper, used to attach the event listeners
		this.mainWrapper = null;
		// the touch event listener
		this.touchListener = null;

		// methods
		this.advanceTimeline = this.advanceTimeline.bind(this);
		this.reverseTimeline = this.reverseTimeline.bind(this);
		this.tweenWipeEffect = this.tweenWipeEffect.bind(this);
		// user event handler
		this.userEventHandler = this.userEventHandler.bind(this);
		// toggle block event triggering
		this.toggleEventBlock = this.toggleEventBlock.bind(this);
	}

	/** General Event Handler
	 * Takes the event and depending on the type, goes to the
	 * next or previous label in the main timeline.
	*/
	userEventHandler (e) {
		const {
			type, // keyup, wheel, swipe
			deltaY, // (< 0): up - (> 0): down
			keyCode, // up: 38 - down: 40
			pointerType, // touch identifies the event type
			direction, // for touch events only - 8: up/next - 16: down/previous
		} = e;
		const {
			eventsBlocked,
			currentLabel,
			totalProjects
		} = this.state;
		switch (type) {
			case "keyup":
				if ( keyCode == 38 && !eventsBlocked && currentLabel > 0 ) {
					// block any other event
					this.toggleEventBlock();
					// previous project
					this.reverseTimeline();
				}
				if ( keyCode == 40 && !eventsBlocked && currentLabel < totalProjects - 1 ) {
					// block any other event
					this.toggleEventBlock();
					// next project
					this.advanceTimeline();
				}
				break;
			case "wheel":
				if ( deltaY < 0 && !eventsBlocked && currentLabel > 0 ) {
					// block any other event
					this.toggleEventBlock();
					// previous project
					this.reverseTimeline();
				}
				if ( deltaY > 0 && !eventsBlocked && currentLabel < totalProjects - 1 ) {
					// block any other event
					this.toggleEventBlock();
					// next project
					this.advanceTimeline();
				}
				break;
			case "swipe":
				if ( pointerType == "touch" && direction == 16 && !eventsBlocked && currentLabel > 0 ) {
					// swipe down, previous project
					// block any other event
					this.toggleEventBlock();
					// previous project
					this.reverseTimeline();
				}
				if ( pointerType == "touch" && direction == 8 && !eventsBlocked && currentLabel < totalProjects - 1 ) {
					// swipe up, next project
					// block any other event
					this.toggleEventBlock();
					// next project
					this.advanceTimeline();
				}
		}
	}

	toggleEventBlock () {
		this.setState({ eventsBlocked: !this.state.eventsBlocked });
	}

	componentDidMount(){
		// attach event listeners: key, wheel and touch
		window.addEventListener("wheel", this.userEventHandler );
		window.addEventListener("keyup", this.userEventHandler );

		// CREATE THE TOUCH EVENT INSTANCE
		this.touchListener = new Hammer(this.mainWrapper);
    // set the swipe direction to vertical
    this.touchListener.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL });
    // get the swipe event
		this.touchListener.on("swipe", this.userEventHandler/* function(e){
			console.log( e );
      const { pointerType, direction } = e;
      // use only for touch events
      if ( pointerType == "touch" ) {
        console.log( pointerType, direction );
      }
    } */);

    let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);


    var AllStacks = $(".intro");
    var StaggerNumber = $(".stagger-number");
    var StaggerOverview = $(".stagger-overview");
		var StaggerImage = $(".stagger-image");
		var ToggleSwitch = $(".toggle-switch");

		var tl = new TimelineLite();
		tl.to(StaggerImage, 2.5, {opacity: "1", ease: Expo.easeOut}, "+=0.75");
		tl.to(AllStacks, 2, {x: "0", ease: Expo.easeInOut}, "-=2.3");
		tl.to(this.titleElements[0], 2, {opacity: "1", transform: "rotateX(0) rotate(0)", x: "0", ease: Power2.easeOut}, "-=0.75");
		tl.to(StaggerOverview, 2.5, {y: "0", ease: Expo.easeOut}, "-=0.95");
		tl.staggerTo(StaggerNumber, 1, {y: "0", ease: Power2.easeOut}, 0.05, "-=2");
		tl.staggerTo(ToggleSwitch, 2, {y: "0", rotation: "0", opacity: "1", ease: Expo.easeOut}, 0.15, "-=2");

		// add the instances to the main timeline
		pageSeries.forEach( (e, i) => {
			const t = TweenLite.to(this.numberContainer, 1.25, {
				yPercent: -(16.6 * (i + 1)),
				ease: Expo.easeInOut
			});
			const t1 = TweenLite.to(this.titlesContainer, 1.25, {
				yPercent: -(100 / this.state.totalProjects) * (i + 1),
				ease: Expo.easeInOut
			});
			const t2 = TweenLite.to(this.projectContainer, 1.45, {
				xPercent: -(100 / 6) * (i + 1),
				ease: Expo.easeInOut
		});
			const t3 = TweenLite.to(this.keyNumberContainer, 1.25, {
				yPercent: -(16.66 * (i + 1)),
				ease: Expo.easeInOut
			});
			// title timeline
			// we use a timeline, because depending on the index
			// we might have to rotate one or two titles
			const { titleElements } = this;
			const tl1 = new TimelineLite({ paused: true })
				.to(titleElements[i], 1.25, {
					opacity: "0",
          transform: "rotateX(90deg) rotate(12deg) translateZ(0) translateX(-40px)",
					ease: Expo.easeInOut
				})
				.to(titleElements[i + 1] ? titleElements[i + 1] : {}, 1.25, {
					opacity: "1",
          transform: "rotateX(0deg) rotate(0deg) translateZ(0) translateX(0)",
					ease: Expo.easeInOut
				}, 0);
			//
			this.homeTimeline.add([t, t1, t2, t3, tl1.play()], `slide-${i}`);
		});
	}

	tweenWipeEffect (i) {
		const { wipeElement } = this;
		TweenLite.killTweensOf(wipeElement);
		const t = new TimelineLite({ onComplete: this.toggleEventBlock })
			.to( wipeElement, 0.85, {
				x: 0, ease: Expo.easeInOut
			})
			.to( wipeElement, 0.85, {
				x: "100%", ease: Expo.easeInOut
			}, 0.9)
			.set( wipeElement, {
				clearProps: "all"
			});
	}

	advanceTimeline () {
		const { currentLabel, totalProjects } = this.state;
		if ( currentLabel < totalProjects - 1 ) {
			this.setState({
				currentLabel: currentLabel + 1
			});
			this.tweenWipeEffect(currentLabel + 1);
			this.homeTimeline.tweenTo(`slide-${currentLabel + 1}`);
		}
	}

	reverseTimeline () {
		const { currentLabel } = this.state;
		if ( currentLabel > 0 ) {
			this.setState({
				currentLabel: currentLabel - 1
			});
			this.tweenWipeEffect(currentLabel - 1);
			this.homeTimeline.tweenTo(`slide-${currentLabel - 1}`);
		}
	}

	componentWillUnmount() {
		// remove all the event listeners: key, wheel and touch
		window.removeEventListener( "wheel", this.userEventHandler );
		window.removeEventListener( "keyup", this.userEventHandler );
	}


	linkClickHandler(e){
		// console.log ( e.target.getAttribute("data-target") );
		console.log(e);
		console.log( e.target );
		this.props.updateNavRoute( e.target.getAttribute("data-target") );
	}

	render(){
		const { backgroundImage, totalProjects } = this.state;
		return <div className="main-wrapper roll-wrapper">
		<Nav />

			<div className="content-container">

				<div className="object-stay-wrapper">
				<div className="object-stay-inner">

				{/* SERIES PRE */}
				<div className="overflow-hidden series-pre">
					<p className="moved-top stagger-overview">Scroll for next</p>
				</div>

					{/* SERIES TITLES */}


						{/* IMAGE COUNTER */}
						<div className="overflow-hidden hidden-numbers">
							<div className="project-counter project-ct">
								<div className="number-container" ref={e => this.numberContainer = e}>

                  {pageSeries.map( (e, i) =>
                    <div
										className="image-number moved-top stagger-overview"
										key={`${e.id}-num`}
									>{e.type} ({e.number})
									</div>)}
								</div>
							</div>
						</div>
  			</div>
				</div>
      	{/* SERIES BACKGROUNDS */}
        <div className="series-bg-wrapper">
        <div className="overflow-hidden hidden-titles" ref={ e => this.mainWrapper = e }>
          <div className="title-container project-ct">
            <div ref={e => this.titlesContainer = e}>
              {pageSeries.map( (e, i) => <h2
                  className="project-title corkscrew-titles"
                  key={e.id}
                  ref={ e => this.titleElements[i] = e }
                  data-target={e.routeUrl}
                  onClick={this.linkClickHandler}
                >{e.title}</h2>
              )}
            </div>
          </div>
        </div>
          {/* WIPE CONTAINER */}
          <div className="wipe-container">
            <div className="wipe-element" ref={e => this.wipeElement = e}></div>
          </div>

          {/* PROJECT BACKGROUND */}
          <div className="series-bg-overview" ref={ e => this.mainWrapper = e }>
            <div ref={e => this.projectContainer = e} className="intro">
						<div className="series series-1">
							<DomesticNoir />
						</div>
						<div className="series series-2">
							<Daylight />
						</div>
						<div className="series series-3">
							<Moody />
						</div>
						<div className="series series-4">
							<Dark />
						</div>
						<div className="series series-5">
							<Gritty />
						</div>
						<div className="series series-6">
							<Burns />
						</div>
          </div>
          <div className="overflow-hidden key-number-wrapper">
            <div className="large-key-number" ref={e => this.keyNumberContainer = e}>
              {pageSeries.map( (e, i) => <div
                className="moved-top stagger-number"
                key={`${e.id}-num`}
              >
                {e.keyNumber}
              </div>)}
            </div>
            <div className="overflow-hidden">
              <div className="large-key-number final-key-number moved-top stagger-number">
                .6
              </div>
            </div>
          </div>
        </div>

          </div>

          <div className="scroll-wrapper">

          </div>

					{/* TOGGLES */}
					<div className="toggle-wrapper">
						<div className="toggle-wrapper-inner">
							<div className="toggle-switch swipe-series">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                <rect x="12.5" y="15.5" width="11" height="9" stroke="black" className="stroke-rec"/>
                <rect x="26" y="15" width="2" height="1" fill="black"/>
                <rect x="26" y="15" width="1" height="10" fill="black"/>
                <rect x="26" y="24" width="2" height="1" fill="black"/>
              </svg>
							</div>
							<div className="toggle-switch stacks-series">
              <div class="click-switch" onClick={this.linkClickHandler} data-target="/overview"></div>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                <rect x="26" y="23" width="2" height="1.2" transform="rotate(90 26 23)" fill="black"/>
                <rect x="26" y="23" width="1" height="12" transform="rotate(90 26 23)" fill="black"/>
                <rect x="15.2" y="23" width="2" height="1.2" transform="rotate(90 15.2 23)" fill="black"/>
                <rect x="14.5" y="15.5" width="11" height="5" stroke="black" className="stroke-rec"/>
              </svg>
							</div>
						</div>
					</div>

  			</div>



	</div>;
	}
}

const mapStateToProps = state => ({
	targetRoute: state.targetRoute
});

const sideMenuActions = { updateNavRoute };

export default connect( mapStateToProps, sideMenuActions )( Roll );
