import { combineReducers } from "redux";

import {
  UPDATE_NAV_ROUTE,
  UPDATE_PROJECTS_DATA
} from "../actions/main-actions";

const updateNavRouteReducer = (state = null, action) => {
  if (action.type === UPDATE_NAV_ROUTE) {
    return action.payload;
  }
  return state;
};

const updateProjectsDataReducer = (state = null, action) => {
  if (action.type === UPDATE_PROJECTS_DATA) {
    return action.payload;
  }
  return state;
};

export const MainReducer = combineReducers({
  targetRoute: updateNavRouteReducer,
  projectsData: updateProjectsDataReducer
});
