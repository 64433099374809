import React, { Component } from "react";
import { connect } from "react-redux";
import { updateNavRoute } from "../../actions/main-actions";
const colorTl = new TimelineMax({paused: true,reversed: true});
import projectsData from "../../scripts/projects-data.json";

const defaultProps = {};
const propTypes = {};

class Info extends Component {

	constructor(){
		super();
    this.state = {
      menuActive: false,
    }
    this.toggleColorSwitchActive = this.toggleColorSwitchActive.bind(this);
    this.toggleColorSwitch = this.toggleColorSwitch.bind(this);
  }

		componentDidMount(){
      this.colorswitch();
			window.scrollTo(0, 0);

			var StaggerNav = $(".stagger-nav");

			var tl = new TimelineLite();
			tl.staggerTo(StaggerNav, 1, {y: "0", ease: Power2.easeOut}, 0.15, "1.5");
			}

    toggleColorSwitchActive() {
      this.setState({
        menuActive: !this.state.menuActive
      })
    }

    switchClickHandler(e) {
      const { toggleColorSwitchActive } = this;
      setTimeout(function(){
      colorTl.reversed() ? colorTl.play() : colorTl.reverse();
      toggleColorSwitchActive();
    }, 100);
    }

    colorswitch() {
      var MenuExpander = $(".menu-expander");
			var MenuFixed = $(".menu-fixed");
      var MenuItems = $(".menu-items");
      var SwitchLess = $(".more-less");

      colorTl
			  .to(MenuFixed, 0, {className:"+=menu-z-high"})
        .to(MenuExpander, 0, {className:"+=menu-active"})
        .staggerTo(MenuItems, 0.65, {y:"0", ease: Power2.easeOut}, 0.05, "+=0.5")
        .to(SwitchLess, 0, {className:"+=less-more"}, "-=1.75")

      var progress = colorTl.progress(); //gets current progress
      return colorTl;
    }

    toggleColorSwitch(e) {
      if (e) {
          e.preventDefault();
      }
      colorTl.reversed() ? colorTl.play() : colorTl.reverse();
      this.setState({
        menuActive: !this.state.menuActive
      })
    }

	render(){
		return <div className="info-wrapper-outer">
          <div className="info-wrapper">
						<div className="more-info-wrapper">
							<div className="overflow-hidden">
								<div className="stagger-nav moved-top eye-wrapper">
									<svg width="20" height="10" viewBox="0 0 20 10" fill="none" onClick={this.toggleColorSwitch}>
										<path d="M1 5.35468C3.70604 2.16295 11.1164 -2.38875 19.1096 4.93837" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
										<path d="M17.0005 6.60344C14.1611 8.91461 7.29966 11.3515 1.79893 4.58411" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
										<path className="pupil" fillRule="evenodd" clipRule="evenodd" d="M12.4327 4.65248C12.1726 4.86946 11.8378 5 11.4726 5C10.6441 5 9.97256 4.32843 9.97256 3.5C9.97256 3.10435 10.1257 2.74447 10.376 2.47645C10.2379 2.45277 10.0959 2.44043 9.95101 2.44043C8.57146 2.44043 7.45312 3.55877 7.45312 4.93831C7.45312 6.31785 8.57146 7.43619 9.95101 7.43619C11.3305 7.43619 12.4489 6.31785 12.4489 4.93831C12.4489 4.84165 12.4434 4.74628 12.4327 4.65248Z" fill="white"/>
									</svg>
									</div>
								</div>
								<div className="overflow-hidden">
									<div className="more-less stagger-nav moved-top" onClick={this.toggleColorSwitch}>info</div>
								</div>
						</div>
            </div>
	</div>;
	}
}

const mapStateToProps = state => ({
  targetRoute: state.targetRoute
});

const sideMenuActions = { updateNavRoute };

export default connect( mapStateToProps, sideMenuActions )(Info);
