import React, { Component } from "react";
import { render } from "react-dom";
// redux
import { createStore } from "redux";
// react redux
import { Provider } from "react-redux";
// react router
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// main reducer
import { MainReducer } from "./reducers/main-reducer";
import AnimatedOverlay from "./components/overlay";
import Nav from "./components/navigation/navigation";
import Home from "./components/home/home";
import Roll from "./components/series/roll";
import Overview from "./components/series/overview";
import SeriesBase from "./components/photoseries/project-base";
import Error from "./components/error";

// get the styles entry point
import "./styles/main.scss";

// create the store
const appStore = createStore(MainReducer);

class App extends Component {
  componentDidMount() {}

  render() {
    return (
      <Provider store={appStore}>
        <BrowserRouter>
          <div>
            <Route path="/" component={AnimatedOverlay} />
            <div className="page-transition-wrapper">
             <Switch>
                <Route exact path="/index.html">
                  <Redirect to="/" />
                </Route>
                <Route exact path="/" component={Home} />
                <Route exact path="/home" component={Home} />
                <Route exact path="/roll" component={Roll} />
                <Route exact path="/overview" component={Overview} />
                <Route path="/series/:id" component={SeriesBase} />
                <Route component={Error} />
              </Switch>
            </div>
          </div>
        </BrowserRouter>
      </Provider>
    );
  }
}

render(<App />, document.getElementById("app-wrap"));
