import React, { Component } from "react";
import { connect } from "react-redux";
import { updateNavRoute } from "../../actions/main-actions";
import Hammer from "hammerjs";
import Nav from "../../components/navigation/navigation";

// projects data
import projectsData from "../../scripts/projects-data.json";

const defaultProps = {};
const propTypes = {};

class Home extends Component {

	constructor(){
		super();
		};

			componentDidMount(){
				window.scrollTo(0, 0);

				var PreloadWrapper = $(".preload-wrapper");
				var PreloadText = $(".preload-text");
				var OverflowWrapper = $(".overflow-wrapper");
	      var BlackWrapper = $(".black-text");
	      var WhiteRec = $(".white-rec");
				var BlackText = $(".black-text-push");
				var PreLoadBG = $(".preload-background");
				var Logo = $(".logo");
				var StaggerHome = $(".stagger-home");
				var Lost = $(".lost-monogram");
				var BackgroundBar = $(".background-bar");

	      var tl = new TimelineLite();
				tl.to(WhiteRec, 1, {y: "0", skewX: "-15", ease: Power2.easeOut}, "1.5");
				tl.to(PreloadText, 1, {y: "0", ease: Power2.easeOut}, "1.65");
				tl.to(OverflowWrapper, 0, {height: "24px"}, "3.15");
				tl.to(BlackWrapper, 1.25, {y: "24px", ease: Power2.easeOut}, "3.25");
				tl.to(BlackText, 1.25, {y: "-24px", ease: Power2.easeOut}, "3.25");
				tl.to(PreloadText, 0.75, {y: "-105%", ease: Power2.easeIn}, "5.25");
				tl.to(WhiteRec, 0.75, {y: "-105%", skewX: "-15", ease: Power2.easeIn}, "5.3");
				tl.to(PreLoadBG, 1, {height: "0", ease: Sine.easeOut}, "5.8");
				tl.to(PreloadWrapper, 0, {display: "none"}, "6.8");
				tl.staggerTo(StaggerHome, 2, {y: "0", ease: Power2.easeOut}, 0.15, "6.3");
				tl.staggerTo(Lost, 2, {transform: "rotateX(0) rotate(0)", y: "0", ease: Circ.easeOut}, 0.15, "6.4");
				tl.to(BackgroundBar, 0, {visibility: "visible"}, "7");


				}

	render(){
		return <div className="main-wrapper">

		<div className="preload-wrapper">
			<div className="preloader-inner-wrapper">
				<div className="preload-background"></div>
				<div className="white-text">
					<div className="overflow-hidden">
						<p className="preload-text moved-top">Digital & <span>35</span>mm zine</p>
					</div>
					<div className="overflow-hidden">
						<p className="preload-text moved-top">beautiful ordinary</p>
					</div>
				</div>
				<div className="black-text">
					<div className="overflow-wrapper">
						<div className="white-rec moved-top"></div>
						<div className="black-text-push">
							<div className="overflow-hidden">
								<p className="preload-text moved-top">Narrating the</p>
							</div>
							<div className="overflow-hidden">
								<p className="preload-text moved-top">I'm not a photographer</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="main-screen">
		<Nav />

			<div className="home-image-wrapper">
					<div className="home-image"></div>

						<div className="background-bar bar-one"></div>
						<div className="background-bar bar-two"></div>
						<div className="background-bar bar-three"></div>
						<div className="background-bar bar-four"></div>
						<div className="background-bar bar-five"></div>
						<div className="background-bar bar-six"></div>
						<div className="background-bar bar-seven"></div>
						<div className="background-bar bar-eight"></div>
						<div className="background-bar bar-nine"></div>
						<div className="background-bar bar-ten"></div>

						<div className="alt-background alt-background-one"></div>
						<div className="alt-background alt-background-two"></div>
						<div className="alt-background alt-background-three"></div>
						<div className="alt-background alt-background-four"></div>
						<div className="alt-background alt-background-five"></div>
						<div className="alt-background alt-background-six"></div>
						<div className="alt-background alt-background-seven"></div>
						<div className="alt-background alt-background-eight"></div>
						<div className="alt-background alt-background-nine"></div>
						<div className="alt-background alt-background-ten"></div>

					<div className="grid">
						<div className="grid-left">
							<div className="narrating-wrapper">
								<div className="overflow-hidden">
									<p className="stagger-home moved-top narrating">
										Narrating the
									</p>
								</div>
								<div className="overflow-hidden">
									<p className="stagger-home moved-top narrating">
										beautiful ordinary
									</p>
								</div>
							</div>
							<div className="overflow-hidden">
								<p className="stagger-home moved-top">
									© 2021
								</p>
							</div>
						</div>

						<div className="grid-right">
							<div>
								<div className="flex-it">
									<h1 className="stagger-home moved-top lost-monogram">
										L
									</h1>
									<h1 className="stagger-home moved-top lost-monogram">
										o
									</h1>
									<h1 className="stagger-home moved-top lost-monogram">
										s
									</h1>
									<h1 className="stagger-home moved-top lost-monogram">
										t
									</h1>
								</div>
								<div className="overflow-hidden">
									<p className="stagger-home moved-top japanese">
										(負けた)
									</p>
								</div>
							</div>
							<div className="grid-right-bottom">
								<div>
									<div className="overflow-hidden">
										<p className="stagger-home moved-top">
											A collection of digital and 35mm
										</p>
									</div>
									<div className="overflow-hidden margin-minus">
										<p className="stagger-home moved-top">
											photos from my travels around the world.
										</p>
									</div>
								</div>
								<div className="overflow-hidden asterix-wrapper">
										<img src="/images/asterix.svg" className="stagger-home moved-top"/>
								</div>
							</div>

						</div>
					</div>
			</div>
		</div>

	</div>;
	}
}

const mapStateToProps = state => ({
	targetRoute: state.targetRoute
});

const sideMenuActions = { updateNavRoute };

export default connect( mapStateToProps, sideMenuActions )( Home );
