import React, { Component } from "react";
import { find } from "lodash";
import { connect } from "react-redux";
import { updateNavRoute } from "../../actions/main-actions";
// get the projects data
import projectsData from "../../scripts/projects-data.json";
import Footer from "../../components/navigation/footer";
import Nav from "../../components/navigation/navigation";
import Info from "../../components/navigation/info";
import Expand from "../../components/photoseries/expand";


const imageBackgrounds = [
  ["C2147", " img-block-one displace-rec1", " pr-background1"],
];

class SeriesBase extends Component {
  constructor(props) {
    super(props);
    this.linkClickHandler = this.linkClickHandler.bind(this);
    this.nextCaseWrapper = null;
    this.state = {
      // singleData: null,
      nextProject: null,
      currentProjectData: null,
      currentProjectIndex: null
    };
  }

  componentDidMount() {
    const {
      projectsData,
      match: {
        params: { id }
      }
    } = this.props;
    if (projectsData !== null) {
      this.setState({
        currentProjectData: projectsData[id],
        currentProjectIndex: projectsData[id].projectIndex,
        nextProject: find(this.props.projectsData, {
          projectIndex: projectsData[id].projectIndex + 1
        })
      });
    }

    // this.setState({
    //   singleData: currentProject,
    //   nextProject
    // });

    window.scrollTo(0, 0);
  }

  componentDidUpdate(props, state) {
    const {
      match: {
        params: { id }
      },
      projectsData
    } = this.props;
    if (props.projectsData === null) {
      // First render set the state
      this.setState({
        currentProjectData: projectsData[id],
        currentProjectIndex: projectsData[id].projectIndex,
        nextProject: find(this.props.projectsData, {
          projectIndex: projectsData[id].projectIndex + 1
        })
      });
    }
    if (props.match.params.id !== id) {
      // The project id has changed, update the state
      this.setState({
        currentProjectData: projectsData[id],
        currentProjectIndex: projectsData[id].projectIndex,
        nextProject: find(this.props.projectsData, {
          projectIndex: projectsData[id].projectIndex + 1
        })
      });
      window.scrollTo(0, 0);
    }
    if (state.currentProjectIndex !== this.state.currentProjectIndex) {

      var StaggerFields = $(".stagger-fields");
      var SeriesInfo = $(".info-fade");
      var Images = $(".image-fade");
      var Title = $(".rotate-x-animation");
      var StaggerNext = $(".animation-link-wrapper");

      var tl = new TimelineLite();
      tl.staggerTo(StaggerFields, 1, {y: "0", ease: Power2.easeOut}, 0.15, "+=1.5");
      tl.to(Title, 2.5, {transform: "rotateX(0) rotate(0)", y: "0", ease: Circ.easeOut}, "-=0.75");
      tl.to(SeriesInfo, 2, {y: "0", opacity: "1", ease: Power2.easeOut}, "+0.5");
      tl.staggerTo(StaggerNext, 2, {top: "0", ease: Power2.easeOut}, 0.15, "+0.5");
      tl.to(Images, 2, {y: "0", opacity: "1", ease: Power2.easeOut}, "+2");
    }
  }

  linkClickHandler(e) {
		this.props.updateNavRoute( e.target.getAttribute("data-target") );
  }

  render() {
    if (this.state.currentProjectData === null) return "";
    const {
      backgroundImage,
      photoNumber,
      seriesTitle,
      seriesShotIn,
      seriesShotInLang,
      seriesShotWith,
      handleBar,
      fullScreenImages,
      fullScreenImagesFolder,
      projectIndex,
      shotWith,
      shotWith2,
      locationHighlight1,
      locationHighlight2,
      locationHighlight3,
      filmRoll1,
      filmRoll2,
      filmRoll3,
      footerImage
    } = this.state.currentProjectData;
    const { nextProject } = this.state;
    return (
      <div>
      <Nav />
      <Info />
        <div className="photo-series-wrapper">
          <div className="top-info-columns">

            <div className="top-right-column">
              <div className="top-right-column-inner">
                  <div className="overflow-hidden">
                    <p className="moved-top stagger-fields">{seriesShotWith} {photoNumber}</p>
                  </div>
              </div>
            </div>

            <div className="series-title-wrapper">
              <h1 className="rotate-x-animation">{seriesTitle}</h1>
            </div>

            <div className="top-right-column">
              <div className="top-right-column-inner">
            <div className="overflow-hidden first-tight-line">
              <p className="moved-top stagger-fields mark-bold">{seriesShotIn}</p>
            </div>
            <div className="overflow-hidden">
              <p className="moved-top stagger-fields">{seriesShotInLang}</p>
            </div>
        </div>
      </div>

          </div>
          <div className="photo-wrapper">
            <div className="photo-wrapper-inner">
            <Expand />
              <section className="full-screen-image-wrapper">
                {fullScreenImages.map(e => (
                  <img
                    key={e}
                    className="full-screen-image fade-in image-fade"
                    loading="lazy"
                    src={fullScreenImagesFolder + e}
                  />
                ))}
              </section>
            </div>
          </div>

          <section className="next-series-wrapper">
            <div className="overflow-hidden series-index">
              <p className="next-series-label stagger-fields moved-top">Series index</p>
            </div>
            <div className="next-series-who-wrapper">
            <ul>
              <li>
                <div className="overflow-hidden counter-inc-wrapper">
                  <div className="animation-link-wrapper">
                    <h5 className="next-series-who first-next-link" onClick={this.linkClickHandler} data-target="/series/domesticnoir">Domestic Noir</h5>
                    <h5 className="next-series-who scnd-next-link" onClick={this.linkClickHandler} data-target="/series/domesticnoir">Domestic Noir</h5>
                  </div>
                </div>
                <div className="next-series-image next-image-one"></div>
              </li>
              <li>
                <div className="overflow-hidden counter-inc-wrapper">
                  <div className="animation-link-wrapper">
                    <h5 className="next-series-who first-next-link" onClick={this.linkClickHandler} data-target="/series/daylight">Daylight</h5>
                    <h5 className="next-series-who scnd-next-link" onClick={this.linkClickHandler} data-target="/series/daylight">Daylight</h5>
                  </div>
                </div>
                <div className="next-series-image next-image-two"></div>
              </li>
              <li>
                <div className="overflow-hidden counter-inc-wrapper">
                  <div className="animation-link-wrapper">
                    <h5 className="next-series-who first-next-link" onClick={this.linkClickHandler} data-target="/series/moody">Moody</h5>
                    <h5 className="next-series-who scnd-next-link" onClick={this.linkClickHandler} data-target="/series/moody">Moody</h5>
                  </div>
                </div>
                <div className="next-series-image next-image-three"></div>
              </li>
              <li>
                <div className="overflow-hidden counter-inc-wrapper">
                  <div className="animation-link-wrapper">
                    <h5 className="next-series-who first-next-link" onClick={this.linkClickHandler} data-target="/series/dark">Dark</h5>
                    <h5 className="next-series-who scnd-next-link" onClick={this.linkClickHandler} data-target="/series/dark">Dark</h5>
                  </div>
                </div>
                <div className="next-series-image next-image-four"></div>
              </li>
              <li>
                <div className="overflow-hidden counter-inc-wrapper">
                  <div className="animation-link-wrapper">
                    <h5 className="next-series-who first-next-link" onClick={this.linkClickHandler} data-target="/series/gritty">Gritty</h5>
                    <h5 className="next-series-who scnd-next-link" onClick={this.linkClickHandler} data-target="/series/gritty">Gritty</h5>
                  </div>
                </div>
                <div className="next-series-image next-image-five"></div>
              </li>
              <li>
                <div className="overflow-hidden counter-inc-wrapper">
                    <div className="animation-link-wrapper">
                      <h5 className="next-series-who first-next-link" onClick={this.linkClickHandler} data-target="/series/burns">Burns & Blurs</h5>
                      <h5 className="next-series-who scnd-next-link" onClick={this.linkClickHandler} data-target="/series/burns">Burns & Blurs</h5>
                    </div>
                </div>
                <div className="next-series-image next-image-six"></div>
              </li>
              </ul>
            </div>
          </section>

        </div>
        <Footer />

        <div className="menu-fixed">
          <div className="menu-expander"></div>
          <div className="menu-wrapper">
            <div className="menu-column-wrapper">
              <div className="menu-column-left">
                <div className="overflow-hidden bottom-16">
                  <div className="small-menu-titles moved-top menu-items">Shot with</div>
                </div>
                <div className="camera-wrapper">
                  <div className="overflow-hidden">
                    <div className="moved-top camera-type menu-items">{shotWith}</div>
                  </div>
                  <div className="overflow-hidden">
                    <div className="moved-top camera-type menu-items">{shotWith2}</div>
                  </div>
                </div>
              </div>
              <div className="menu-column-center">

                <div className="menu-v-stack">
                  <div className="overflow-hidden bottom-16">
                    <div className="small-menu-titles moved-top menu-items">Location</div>
                  </div>
                  <div className="overflow-hidden">
                    <div className="moved-top series-type menu-items">{seriesShotIn}</div>
                  </div>
                  <div className="overflow-hidden">
                    <div className="moved-top series-type menu-items">{seriesShotInLang}</div>
                  </div>
                </div>

                <div className="menu-v-stack">
                  <div className="overflow-hidden bottom-16">
                    <div className="small-menu-titles moved-top menu-items">Location Highlights</div>
                  </div>
                  <div className="overflow-hidden">
                    <div className="moved-top series-type menu-items">{locationHighlight1}</div>
                  </div>
                  <div className="overflow-hidden">
                    <div className="moved-top series-type menu-items">{locationHighlight2}</div>
                  </div>
                  <div className="overflow-hidden">
                    <div className="moved-top series-type menu-items">{locationHighlight3}</div>
                  </div>
                </div>

                <div className="menu-v-stack">
                  <div className="overflow-hidden bottom-16">
                    <div className="small-menu-titles moved-top menu-items">Shot on</div>
                  </div>
                  <div className="overflow-hidden">
                    <div className="moved-top series-type menu-items">{filmRoll1}</div>
                  </div>
                  <div className="overflow-hidden">
                    <div className="moved-top series-type menu-items">{filmRoll2}</div>
                  </div>
                  <div className="overflow-hidden">
                    <div className="moved-top series-type menu-items">{filmRoll3}</div>
                  </div>
                </div>

              </div>
              <div className="menu-column-right">
                <div className="overflow-hidden bottom-8">
                  <div className="small-menu-titles moved-top menu-items">Series</div>
                </div>
                <div className="overflow-hidden">
                  <div className="moved-top menu-items small-series-title">{seriesTitle}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="left-aligner overflow-hidden">
          <svg className="aligned-moved-top menu-items" width="5" height="8" viewBox="0 0 5 8" fill="none">
            <rect x="2" y="8" width="2" height="8" transform="rotate(-180 2 8)" fill="white"/>
            <rect x="5" y="3" width="2" height="5" transform="rotate(90 5 3)" fill="white"/>
          </svg>
          </div>
          <div className="right-aligner overflow-hidden">
            <svg className="aligned-moved-top menu-items"  width="5" height="8" viewBox="0 0 5 8" fill="none">
              <rect x="3" width="2" height="8" fill="white"/>
              <rect y="5" width="2" height="5" transform="rotate(-90 0 5)" fill="white"/>
            </svg>
          </div>

        </div>
          <div className="black-curtain"></div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  targetRoute: state.targetRoute,
  projectsData: state.projectsData
});

const sideMenuActions = { updateNavRoute };

export default connect(mapStateToProps, sideMenuActions)(SeriesBase);
