export const UPDATE_NAV_ROUTE = "UPDATE_NAV_ROUTE";
export const UPDATE_PROJECTS_DATA = "UPDATE_PROJECTS_DATA";

export const updateNavRoute = (route = null) => {
  return { type: UPDATE_NAV_ROUTE, payload: route };
};

export const updateProjectsData = (projectsData = {}) => {
  return { type: UPDATE_PROJECTS_DATA, payload: projectsData };
};
