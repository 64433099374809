import React, { Component } from "react";
import { connect } from "react-redux";
import { updateNavRoute, updateProjectsData } from "../../actions/main-actions";
import DomesticNoir from "../../components/series/domesticnoir";
import Daylight from "../../components/series/daylight";
import Moody from "../../components/series/moody";
import Dark from "../../components/series/dark";
import Gritty from "../../components/series/gritty";
import Burns from "../../components/series/burns";
import Nav from "../../components/navigation/navigation";

import projectsData from "../../scripts/projects-data.json";

const defaultProps = {};
const propTypes = {};

class Overview extends Component {

  constructor() {
    super();
    this.linkClickHandler = this.linkClickHandler.bind(this);
  }

  componentDidMount() {
    var StaggerOverview = $(".stagger-overview");
		var StaggerImage = $(".stagger-image");
		var ToggleSwitch = $(".toggle-switch");
		var StaggerTitles = $(".corkscrew-titles");

		var tl = new TimelineLite();
		tl.staggerTo(StaggerImage, 2, {opacity: "1", ease: Expo.easeOut}, 0.15, "+=1.95");
		tl.staggerTo(StaggerOverview, 1, {y: "0", ease: Power2.easeOut}, 0.15, "-=2.5");
		tl.staggerTo(ToggleSwitch, 2, {y: "0", rotation: "0", opacity: "1", ease: Expo.easeOut}, 0.15, "-=2.3");
		tl.staggerTo(StaggerTitles, 2, {opacity: "1", transform: "rotateX(0) rotate(0)", x: "0", ease: Power2.easeOut}, 0.15, "-=2.45");
  }

  linkClickHandler(e){
    // console.log ( e.target.getAttribute("data-target") );
    console.log(e);
    console.log( e.target );
    this.props.updateNavRoute( e.target.getAttribute("data-target") );
  }

  render(){
		return <div className="main-wrapper overview-wrapper">
		<Nav />

    <div className="master-stacked-container">

    <div className="series series-1">
      <div className="hidden-titles">
        <h2 className="project-title corkscrew-titles" onClick={this.linkClickHandler} data-target="/series/domesticnoir">
          Domestic Noir
        </h2>
      </div>
      <DomesticNoir />
        <div className="series-title-overview-wrapper">
            <div className="overflow-hidden">
              <div className="image-number moved-top stagger-overview">
                <p>9 images (9)</p>
              </div>
            </div>
        </div>
    </div>
    <div className="series series-2">
      <div className="hidden-titles">
        <h2 className="project-title corkscrew-titles" onClick={this.linkClickHandler} data-target="/series/daylight">
          Daylight
        </h2>
      </div>
      <Daylight />
      <div className="series-title-overview-wrapper">
          <div className="overflow-hidden">
            <div className="image-number moved-top stagger-overview">
              <p>35mm (15)</p>
            </div>
          </div>
      </div>
    </div>
    <div className="series series-3">
      <div className="hidden-titles">
        <h2 className="project-title corkscrew-titles" onClick={this.linkClickHandler} data-target="/series/moody">
          Moody
        </h2>
      </div>
      <Moody />
      <div className="series-title-overview-wrapper">
          <div className="overflow-hidden">
            <div className="image-number moved-top stagger-overview">
              <p>35mm (11)</p>
            </div>
          </div>
      </div>
    </div>
    <div className="series series-4">
      <div className="hidden-titles">
        <h2 className="project-title corkscrew-titles" onClick={this.linkClickHandler} data-target="/series/dark">
          Dark
        </h2>
      </div>
      <Dark />
      <div className="series-title-overview-wrapper">
          <div className="overflow-hidden">
            <div className="image-number moved-top stagger-overview">
              <p>Digital (8)</p>
            </div>
          </div>
      </div>
    </div>
    <div className="series series-5">
      <div className="hidden-titles">
        <h2 className="project-title corkscrew-titles" onClick={this.linkClickHandler} data-target="/series/gritty">
          Gritty
        </h2>
      </div>
      <Gritty />
      <div className="series-title-overview-wrapper">
          <div className="overflow-hidden">
            <div className="image-number moved-top stagger-overview">
              <p>Digital (10)</p>
            </div>
          </div>
      </div>
    </div>
    <div className="series series-6">
      <div className="hidden-titles">
        <h2 className="project-title corkscrew-titles" onClick={this.linkClickHandler} data-target="/series/burns">
          Burns & Blurs
        </h2>
      </div>
      <Burns />
      <div className="series-title-overview-wrapper">
          <div className="overflow-hidden">
            <div className="image-number moved-top stagger-overview">
              <p>35mm (9)</p>
            </div>
          </div>
      </div>
    </div>

    </div>

    <div className="toggle-wrapper">
      <div className="toggle-wrapper-inner">
        <div className="toggle-switch swipe-series">
          <div class="click-switch" onClick={this.linkClickHandler} data-target="/roll"></div>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
              <rect x="12.5" y="15.5" width="11" height="9" stroke="black" className="stroke-rec"/>
              <rect x="26" y="15" width="2" height="1" fill="black"/>
              <rect x="26" y="15" width="1" height="10" fill="black"/>
              <rect x="26" y="24" width="2" height="1" fill="black"/>
            </svg>
          </div>
          <div className="toggle-switch stacks-series">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
            <rect x="26" y="23" width="2" height="1.2" transform="rotate(90 26 23)" fill="black"/>
            <rect x="26" y="23" width="1" height="12" transform="rotate(90 26 23)" fill="black"/>
            <rect x="15.2" y="23" width="2" height="1.2" transform="rotate(90 15.2 23)" fill="black"/>
            <rect x="14.5" y="15.5" width="11" height="5" stroke="black" className="stroke-rec"/>
          </svg>
        </div>
      </div>
    </div>

    </div>;
  	}
  }

const mapStateToProps = state => ({
	targetRoute: state.targetRoute
});

const sideMenuActions = { updateNavRoute };

export default connect( mapStateToProps, sideMenuActions )( Overview );
