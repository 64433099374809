import React, { Component } from "react";
// redux
import { connect } from "react-redux";
// actions
import { updateNavRoute, updateProjectsData } from "../actions/main-actions";

import projectsData from "../scripts/projects-data.json";

const movieLines = [
	"Cut to:",
	"Space Monkey!",
	"Fade in:",
	"Planet Starbucks"
];

class AnimatedOverlay extends Component {
  constructor() {
    super();

    this.state = {
      targetRoute: null,
      usedLine: 0
    };
    this.overlayElement = null;
    this.overlayInTween = null;
    this.overlayOutTween = null;
    this.movieLine = null;
    this.overlayTimeline = new TimelineMax({ paused: true });

    this.overlayInComplete = this.overlayInComplete.bind(this);
    this.overlayOutComplete = this.overlayOutComplete.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
		// console.clear();
		const linesAmount = movieLines.length - 1;
		// if the target route in the props is different from the
		// state, update the used line property in the state
		if( props.targetRoute !== state.targetRoute ) {
			// create the new line index
			let newLineIndex = Math.floor( Math.random() * linesAmount );
			while( newLineIndex === state.usedLine ) {
				newLineIndex = Math.floor( Math.random() * linesAmount );
			}
			// the new line is different, return the new state
			return {
				usedLine: newLineIndex,
				targetRoute: props.targetRoute
			}
		}
		return null;
	}

	overlayInComplete(){
		// the tween is complete, push the new route
		this.props.history.push( this.props.targetRoute );
		this.overlayOutTween.play();
	}

	overlayOutComplete(){
		this.overlayOutTween.pause(0);
		this.overlayInTween.pause(0);
	}

  componentDidUpdate(prevProps) {
    const { targetRoute } = prevProps;
    if (targetRoute !== null && targetRoute !== this.props.targetRoute) {
      // this.overlayInTween.play(0);
      this.overlayTimeline.restart();
    }
  }

  componentDidMount() {
    // Since this component is mounted at the start of the app lifecycle
    // we add the projects data here
    this.props.updateProjectsData(projectsData);
    // the user might come through a bookmark
    // in that case update the store route
    this.props.updateNavRoute(this.props.location.pathname);
    var OverlayElement = $(".overlay-wrap");
    var OverlayDarkElement = $(".dark-overlay");
    var CutTransitions = $(".cut-transitions");

    this.overlayTimeline
      .to(OverlayElement, 0, { display: "flex" })
      .call(this.overlayInComplete, [], this, 1.3)
      .to(OverlayDarkElement, 1.5, { opacity: "1", ease: Power4.easeInOut }, "-=1.5")
      .to(CutTransitions, 1.5, { y: "0", ease: Power4.easeInOut }, "-=1.25")
      .to(CutTransitions, 1.5, { y: "-100", ease: Power4.easeInOut }, "+=0")
      .to(OverlayDarkElement, 1.5, { opacity: "0", ease: Power4.easeInOut }, "2")
      .to(OverlayElement, 0, { display: "none" });
  }

  render() {
    return (
      <div ref={e => (this.overlayElement = e)} className="overlay-wrap">
        <div ref={e => (this.overlayDarkElement = e)} className="dark-overlay">
        </div>
        <div className="overflow-hidden place-cut-transitions">
          <h5 className="cut-transitions moved-top">{ movieLines[this.state.usedLine] }</h5>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  targetRoute: state.targetRoute
});

const overlayActions = { updateNavRoute, updateProjectsData };

export default connect(mapStateToProps, overlayActions)(AnimatedOverlay);
